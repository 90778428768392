/** @jsx jsx */
import { Grid, jsx } from 'theme-ui';

import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Input,
  Label,
  Button,
  Textarea,
  Checkbox,
} from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';

import emsDeliveryTime from '../data/shipping/ems.json';

const ReturnsPage = (props) => {
  const {
    siteUrl,
    gatsbyStorefrontConfig: { storeName },
  } = props.data.site.siteMetadata;
  const {
    pageContext: { hrefLang },
  } = props;

  return (
    <>
      <Helmet
        title={t({
          id: `Returns.Title`,
          message: `Returns, Refunds & Exchanges`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Shipping.MetaDescription`,
            message: `Returns and exchanges are available within 14 days after the delivery date if all the following conditions are met.`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Returns.BreadcrumbsTitle`,
              message: `Returns, Refunds & Exchanges`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 900,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="Returns.Heading">Returns, Refunds & Exchanges</Trans>
        </Heading>
        <Trans id="Returns.Description">
          <Text as="p" mt={[2, 3]}>
            Returns and exchanges are available within 14 days after the
            delivery date if all the following conditions are met:
          </Text>
          <Text as="p" mt={[2, 3]}>
            <ul>
              <li>The product must be in original packaging with tags.</li>
              <li>
                The white paper on the top of hair monotapes must not be
                unsticked or removed.
              </li>
              <li>The seal on the hair monotapes must not be broken.</li>
              <li>The product must not be used, or tampered.</li>
              <li>The hair must not be chemically altered or colored.</li>
              <li>The customer must have a proof of purchase.</li>
            </ul>
          </Text>
          <Text as="p" mt={[2, 3]}>
            You cannot return your hair extensions if the seal is broken. This
            also means that you can’t try on the hair.
          </Text>
          <Text as="p" mt={[2, 3]} sx={{ fontWeight: 'bold' }}>
            Human hair extensions are a hygienic product by law. We take matters
            of hygiene and public safety seriously. We do not allow opened items
            to be returned for the safety of all of our clients.
          </Text>
        </Trans>

        <Heading as="h3" my={[2, 3]}>
          <Trans id="Returns.From.Heading">
            Return & Exchange request form
          </Trans>
        </Heading>

        <Text as="p" mt={[2, 3]}>
          <Trans id="Returns.From.Description">
            Please fill the following form to return or exchange your order.
            After reviewing your return request, we will provide you with the
            return shipping address and additional information.
          </Trans>
        </Text>

        <Box sx={{ maxWidth: 600, mx: 'auto' }} mt={[3, 4]}>
          <form
            method="POST"
            action="https://formsubmit.co/faa836047349b4c22919bd05128524b8"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_next" value={`${siteUrl}/thank-you`} />
            <input type="text" name="_honey" sx={{ display: 'none' }} />
            <input type="hidden" name="_captcha" value="false" />
            <Label htmlFor="name">
              <Trans id="Contact.FormName">Your name</Trans>
            </Label>
            <Input type="name" name="name" mb={[2, 3]} />
            <Label htmlFor="email">
              <Trans id="Contact.FormEmail">E-mail to contact you back</Trans>
            </Label>
            <Input type="email" name="email" mb={[2, 3]} />
            <Label htmlFor="email">
              <Trans id="Contact.FormPhone">Your phone number</Trans>
            </Label>
            <Input type="phone" name="phone" mb={[2, 3]} />
            <Label htmlFor="message">
              <Trans id="Returns.Form.FormMessage">
                Message (please describe why you want to return or exchange)
              </Trans>
            </Label>
            <Textarea name="message" mb={[2, 3]}></Textarea>
            <Label>
              <Trans id="Returns.Form.AttachmentsLabel">
                Attachments (please attach the photos demonstrating the current
                state of the product)
              </Trans>
            </Label>
            <Input
              type="file"
              name="attachment1"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />

            <Input
              type="file"
              name="attachment2"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />

            <Input
              type="file"
              name="attachment3"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />

            <Input
              type="file"
              name="attachment4"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />
            <Trans id="Returns.From.Confirmation">
              <Text as="p" my={[2, 3]}>
                Hereby I confirm that:
              </Text>

              <Label>
                <Checkbox
                  defaultChecked={false}
                  name="originalPackagingConfirm"
                />{' '}
                The product is in original packaging with tags.
              </Label>
              <Label>
                <Checkbox
                  defaultChecked={false}
                  name="whitePaperUnstickConfirm"
                />{' '}
                The white paper on the top of hair monotapes is not unsticked or
                removed.
              </Label>
              <Label>
                <Checkbox defaultChecked={false} name="notOpenedConfirm" /> The
                seal on the hair monotapes is not broken.
              </Label>
              <Label>
                <Checkbox defaultChecked={false} name="notOpenedConfirm" /> The
                product is not used, or tampered.
              </Label>
              <Label>
                <Checkbox defaultChecked={false} name="notAlteredConfirm" /> The
                product is not chemically altered or colored.
              </Label>
              <Label mb={[2, 3]}>
                <Checkbox defaultChecked={false} name="notOpenedConfirm" /> I
                have purchased the order myself.
              </Label>
            </Trans>
            <Input type="hidden" name="RequiredConfirmationsNumber" value="5" />
            <Button type="submit" mb={[2, 3]} px={[4, 5]} py={[2, 3]}>
              <Trans id="Returns.Form.FormButton">Submit request</Trans>
            </Button>
          </form>
        </Box>

        <Heading as="h3" my={[2, 3]}>
          <Trans id="Returns.RefundsHeading">Refunds</Trans>
        </Heading>
        <Trans id="Returns.RefundsDescr</Trans>iption">
          <Text as="p" mt={[2, 3]}>
            Once we accepted your return and receive the returned package,
            please allow 2-4 business days for your return to be processed. As
            soon as we receive confirmation that the item is in resellable
            condition, we will issue a refund to the original form of payment
            utilized for the purchase.
          </Text>
          <Text as="p" mt={[2, 3]}>
            We do not refund the original and return shipping charges. The
            actual shipping cost will be deducted from your refund amount.
          </Text>
        </Trans>
      </Box>
    </>
  );
};

export const ReturnsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default ReturnsPage;
